<template>
    <div>
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--详情-->
            <el-row>
                <el-col :span="12">
                    <el-form size="medium" ref="form" :model="form" label-width="120px">
                        <el-form-item label="原密码">
                            <el-input v-model="form.password" show-password maxlength="26"></el-input>
                        </el-form-item>
                        <el-form-item label="新密码">
                            <el-input v-model="form.newpassword" show-password maxlength="26"></el-input>
                        </el-form-item>
                        <el-form-item label="确认密码">
                            <el-input v-model="form.rnewpassword" show-password maxlength="26"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" size="medium" @click="save">保存</el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            page_name: '修改密码',
            form: {
                password: '',
                newpassword: '',
                rnewpassword: '',
            },
        }
    },
    methods: {
        //保存
        save() {
            // 提交信息
            let postdata = {
                api_name: 'home.user.updatepassword',
                token: this.Tool.get_l_cache('token'),
                password: this.form.password,
                newpassword: this.form.newpassword
            }
            //信息验证
            if (postdata.newpassword != this.form.rnewpassword) {
                this.$message.error('两次新密码不一样')
                return false
            }

            // 发送请求
            this.Tool.post_data('oss', postdata, (json) => {
                console.log('返回数据', json)
                // 处理登录结果
                if (json.code === 0) {
                    // 修改成功 退出，重新登录
                    this.logout()
                } else {
                    this.$message.error(json.message);
                }
            })
        },
        // 退出登录
        logout() {
            // 清空用户信息
            this.Tool.del_l_cache('all')
            this.Tool.del_s_cache('all')

            // 提示并跳转登录页面
            this.$message({
                message: '修改成功，请重新登录', type: 'success',
                duration: this.env.message_duration,
                onClose: () => {
                    location.href = '/'
                }
            });
        }

    }
}
</script>
